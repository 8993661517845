import React, { useState, useEffect } from 'react';

import Preloader from './Preloader';

const RouteWithLoaderPublic = ({ component: Component }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Preloader show={!loaded} />
      <Component />
    </>
  );
};

export default RouteWithLoaderPublic;
