import React from 'react';
import { Image } from '@themesberg/react-bootstrap';

import LoadingSpinner from './assets/loading-spinner.svg';

const Preloader = (props) => {
  const { show } = props;

  return (
    <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? '' : 'show'}`}>
      <Image className="loader-element" src={LoadingSpinner} height={150} />
    </div>
  );
};

export default Preloader;
