import React from 'react';

const SettingsPage = () => (
  <>
    <p>test</p>
    <p>test2</p>
  </>
);

export default SettingsPage;
